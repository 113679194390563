export default function useGalleryClasses(
  imageFormat: GalleryImageFormat,
  additionalClasses?: {
    container?: string | object | []
    image?: string | object | []
  }
) {
  const containerClassList = computed(() => [
    additionalClasses?.container,
    'w-full h-auto relative overflow-hidden',
    {
      'aspect-[2/1]': imageFormat === GALLERY_IMAGE_FORMAT.LANDSCAPE,
      'aspect-[2/3]': imageFormat === GALLERY_IMAGE_FORMAT.PORTRAIT,
      'rounded-full overflow-hidden':
        imageFormat === GALLERY_IMAGE_FORMAT.ROUNDED,
      'aspect-[1/1]':
        imageFormat === GALLERY_IMAGE_FORMAT.ROUNDED ||
        imageFormat === GALLERY_IMAGE_FORMAT.SQUARE,
      'aspect-[16/9]': imageFormat === GALLERY_IMAGE_FORMAT.WIDESCREEN,
    },
  ])

  const imageClassList = computed(() => [
    additionalClasses?.image,
    'absolute top-0 object-cover w-full h-full',
    {
      'rounded-full overflow-hidden':
        imageFormat === GALLERY_IMAGE_FORMAT.ROUNDED,
    },
  ])

  return {
    containerClassList,
    imageClassList,
  }
}
