<template>
  <ModalContent
    :open="true"
    title="Empfehlung der Jugend Filmjury (JFJ)"
    :classes="{
      content: 'px-5 sm:px-8 py-4 overflow-y-auto scrollbar-themed',
      header:
        'h-16 pl-5 sm:pl-8 pr-4 text-lg flex items-center justify-between',
    }"
    :transition="{
      from: TRANSITION_POSITIONS.BOTTOM,
      to: TRANSITION_POSITIONS.CENTER,
    }"
    @close="emit('close')"
  >
    <template #default>
      <div class="flex flex-col space-y-2">
        <span>
          Jugendjurys aus je 10 Schüler:innen bewerten Filme nach Kriterien wie
          Drehbuch, Handlung, Schauspieler:innen, Kamera und vielem mehr.
        </span>
        <span>Zusätzlich dazu wird eine Altersempfehlung ausgesprochen.</span>
        <span>Mehr Informationen unter:</span>
        <NuxtLink
          :external="true"
          to="https://www.jugend-filmjury.com"
          :target="LINK_TARGET.BLANK"
          class="text-primary underline"
        >
          https://www.jugend-filmjury.com
        </NuxtLink>
      </div>
    </template>
  </ModalContent>
</template>

<script setup lang="ts">
const emit = defineEmits(['close'])

defineOptions({
  name: 'JfjModal',
})
</script>
