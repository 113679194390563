export default function joinArrayOfObjectsByAttribute(
  array: any,
  key: string,
  limit = array?.length ?? 1,
  separator = ', '
): string {
  return array
    .slice(0, limit)
    .map((item) => String(item[key]))
    .join(separator)
}
