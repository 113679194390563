import type { Movie } from '#gql/default'

export default function useShowCastGallery(movie: Movie) {
  // Prefer cast with images for castGallery
  const cast = computed(() =>
    [...movie.actors].sort((a, b) => +!!b.profileImage - +!!a.profileImage)
  )

  const showCastGallery = computed(
    () => cast.value.filter(({ profileImage }) => !!profileImage).length >= 3
  )

  return {
    cast,
    showCastGallery,
  }
}
