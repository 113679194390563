import type { AsyncDataRequestStatus } from '#app/composables/asyncData'

import type {
  FetchProgramByDayQuery,
  FetchProgramByDayQueryVariables,
  FetchProgramByMovieQuery,
  FetchProgramByMovieQueryVariables,
  FetchProgramForCinemaShowGroupQuery,
  FetchProgramForCinemaShowGroupQueryVariables,
  FetchCinemasForCinemaCardsQuery,
  FetchCinemasForCinemaCardsQueryVariables,
  FetchCitiesQuery,
  FetchCitiesQueryVariables,
  FetchPersonsQuery,
  FetchPersonsQueryVariables,
  FetchMoviesForMovieCardsQuery,
  FetchMoviesForMovieCardsQueryVariables,
  FetchProgramForMovieCardsQuery,
  FetchProgramForMovieCardsQueryVariables,
  FetchCinemasForMovieQuery,
  FetchCinemasForMovieQueryVariables,
  FetchMoviesForMovieCarouselQuery,
  FetchMoviesForMovieCarouselQueryVariables,
  FetchMoviesForMovieListQuery,
  FetchMoviesForMovieListQueryVariables,
} from '#gql/default'

type KeysOfUnion<UnionType> = UnionType extends UnionType
  ? UnionType[keyof UnionType]
  : never

type Check<T> = T extends FetchProgramByDayQuery
  ? FetchProgramByDayQueryVariables
  : T extends FetchProgramByMovieQuery
    ? FetchProgramByMovieQueryVariables
    : T extends FetchCinemasForCinemaCardsQuery
      ? FetchCinemasForCinemaCardsQueryVariables
      : T extends FetchCitiesQuery
        ? FetchCitiesQueryVariables
        : T extends FetchMoviesForMovieCardsQuery
          ? FetchMoviesForMovieCardsQueryVariables
          : T extends FetchProgramForMovieCardsQuery
            ? FetchProgramForMovieCardsQueryVariables
            : T extends FetchProgramForCinemaShowGroupQuery
              ? FetchProgramForCinemaShowGroupQueryVariables
              : T extends FetchPersonsQuery
                ? FetchPersonsQueryVariables
                : T extends FetchCinemasForMovieQuery
                  ? FetchCinemasForMovieQueryVariables
                  : T extends FetchMoviesForMovieCarouselQuery
                    ? FetchMoviesForMovieCarouselQueryVariables
                    : T extends FetchMoviesForMovieListQuery
                      ? FetchMoviesForMovieListQueryVariables
                      : T extends FetchProgramForMovieCardsQuery
                        ? FetchProgramForMovieCardsQueryVariables
                        : T extends FetchProgramForCinemaShowGroupQuery
                          ? FetchProgramForCinemaShowGroupQueryVariables
                          : never

const OPERATION_TYPE = {
  FETCH_WITH_NEW_PARAMS: 1,
  FETCH_MORE: 2,
  FETCH_FALLBACK: 3,
  NEXT_PAGE: 4,
  PREV_PAGE: 5,
}

export default function useFetchResults<
  T extends
    | FetchProgramByDayQuery
    | FetchProgramByMovieQuery
    | FetchCinemasForCinemaCardsQuery
    | FetchCitiesQuery
    | FetchPersonsQuery
    | FetchMoviesForMovieCardsQuery
    | FetchMoviesForMovieCarouselQuery
    | FetchMoviesForMovieListQuery
    | FetchProgramForMovieCardsQuery
    | FetchProgramForCinemaShowGroupQuery
    | FetchCinemasForMovieQuery,
  K extends Check<T>,
>({
  data,
  status,
  fetchFallbackResults,
  fetchParams,
  variables,
  getVariables,
  pick = 'data',
}: {
  data: Ref<T>
  status: Ref<AsyncDataRequestStatus>
  fetchParams: Ref<any>
  variables: Ref<K>
  fetchFallbackResults: boolean
  getVariables: (fallbackResults: boolean, page: number) => K
  pick?: keyof KeysOfUnion<T>
}) {
  type NestedValue = KeysOfUnion<T>

  function getValue(): NestedValue {
    if (!data.value || Object.keys(data.value).length === 0) {
      return {} as NestedValue
    }

    return Object.values(data.value)[0] as NestedValue
  }

  function getResults(): Array<KeysOfUnion<T>[keyof KeysOfUnion<T>]> {
    const value = getValue()[pick]
    return Array.isArray(value) ? value : []
  }

  // Casts are necessary to ensure that no nested refs exist, see https://github.com/vuejs/core/issues/2136#issuecomment-693524663
  const results = ref(getResults()) as Ref<
    Array<KeysOfUnion<T>[keyof KeysOfUnion<T>]>
  >

  function getPaginatorInfo(): NestedValue['paginatorInfo'] {
    return getValue().paginatorInfo ?? {}
  }

  const operationType = ref()
  const page = computed(() => variables.value.page ?? 1)
  const paginatorInfo = ref(getPaginatorInfo()) as Ref<
    NestedValue['paginatorInfo']
  >

  const pending = computed(() => status.value === 'pending')
  const hasPreviousPages = computed(() => page.value > 1)
  const hasMorePages = computed(() => paginatorInfo.value?.hasMorePages)
  const hasFallbackResults = computed(
    () => operationType.value === OPERATION_TYPE.FETCH_FALLBACK
  )
  const hasResults = computed(
    () =>
      !hasFallbackResults.value &&
      Array.isArray(results.value) &&
      results.value.length > 0
  )

  function next() {
    operationType.value = OPERATION_TYPE.NEXT_PAGE
    variables.value = getVariables(false, page.value + 1)
  }

  function prev() {
    operationType.value = OPERATION_TYPE.PREV_PAGE
    variables.value = getVariables(false, page.value - 1)
  }

  function fetchMore() {
    operationType.value = OPERATION_TYPE.FETCH_MORE
    variables.value = getVariables(false, page.value + 1)
  }

  function fetchFallback() {
    const fallbackVariables = getVariables(true, 1)
    const normalVariables = getVariables(false, 1)
    if (JSON.stringify(fallbackVariables) === JSON.stringify(normalVariables)) {
      // no need to fetch fallback results if they are going to return the same as the normal ones
      return
    }

    operationType.value = OPERATION_TYPE.FETCH_FALLBACK
    variables.value = getVariables(true, 1)
  }

  function fetchWithNewParams() {
    operationType.value = OPERATION_TYPE.FETCH_WITH_NEW_PARAMS
    variables.value = getVariables(false, 1)
  }

  watch(fetchParams, fetchWithNewParams)

  watch(status, () => {
    if (status.value !== 'success') {
      return
    }

    if (operationType.value === OPERATION_TYPE.FETCH_MORE) {
      paginatorInfo.value = getPaginatorInfo()
      if (Array.isArray(results.value)) {
        results.value = results.value.concat(getResults()) as Array<
          KeysOfUnion<T>[keyof KeysOfUnion<T>]
        >
      }
    } else {
      results.value = getResults()
      paginatorInfo.value = getPaginatorInfo()

      if (
        operationType.value === OPERATION_TYPE.FETCH_WITH_NEW_PARAMS &&
        !hasResults.value &&
        fetchFallbackResults
      ) {
        fetchFallback()
      }
    }
  })

  return {
    results,
    pending,
    hasMorePages,
    hasPreviousPages,
    hasFallbackResults,
    next,
    prev,
    fetchMore,
    paginatorInfo,
  }
}
