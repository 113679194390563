<template>
  <ShowGroupsMovieFetch
    :fetch-key="`movie-${route.params.movieSlug}`"
    :fetch-params="params"
  >
    <template #default="{ movie, uuid }">
      <template v-if="movie">
        <MovieHead
          :movie
          :uuid
          :thumbnail-image="getImage(movie, 'thumbnailImage', movie.title)"
        />
        <PageContainer>
          <NuxtPage :movie :uuid />
        </PageContainer>
      </template>
    </template>
  </ShowGroupsMovieFetch>
</template>

<script setup lang="ts">
import type { FetchShowGroupsForMovieQueryVariables } from '#gql/default'

const route = useRoute()
const { config } = useCms()
const params = computed(
  () =>
    ({
      cinemaIds: config.value?.cinemas.map(({ id }) => id),
      showGroups: [route.params.movieSlug],
      first: 1,
      page: 0,
    }) as FetchShowGroupsForMovieQueryVariables
)

defineI18nRoute({
  paths: {
    en: '/movie/[movieSlug]',
    de: '/film/[movieSlug]',
  },
})

defineOptions({
  name: 'MovieSlug',
})
</script>
