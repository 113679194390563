<template>
  <div>
    <div id="kinoheld-eframe" />
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const { t } = useI18n()

const { show, error } = await useShow({
  cinemaId: route.query.cinemaId as string,
  urlSlug: route.params.showSlug as string,
})

if (error.value || !show.value) {
  const { redirectWithError } = useRedirectError()

  await redirectWithError({
    message: error.value ?? REDIRECT_ERROR_STRINGS.NO_SHOW,
    redirect: {
      name: ROUTE_NAMES.MOVIE_PROGRAM,
      params: {
        movieSlug: route.params.movieSlug as string,
      },
    },
    cinemaId: route.query.cinemaId as string,
  })
}

usePageMetas({
  title: `${show.value?.cinema?.name} | ${t('tickets')}`,
  description: t('description', {
    showName: show.value?.name,
    cinemaName: show.value?.cinema?.name,
  }),
  ...show.value?.meta,
})

useLegacyWidget({
  iframeName: 'kinoheld-widget',
  enableReservations: show.value?.cinema?.isReservationsEnabled,
  cinemaPath: `kino-${show.value?.cinema?.city.urlSlug}/${show.value?.cinema?.urlSlug}/show/${show.value?.urlSlug}`,
})

const { $gtm } = useNuxtApp()

onMounted(() => {
  $gtm.trackPageView()
})

defineI18nRoute({
  paths: {
    en: '/movie/[movieSlug]/show/[showSlug]',
    de: '/film/[movieSlug]/vorstellung/[showSlug]',
  },
})

defineOptions({
  name: 'ShowSlug',
})
</script>

<i18n>
de:
  tickets: "Kinotickets"
  description: "Jetzt bei {cinemaName} Tickets für den Film {showName} schnell und bequem online kaufen."
</i18n>
