<template>
  <div></div>
</template>

<script setup lang="ts">
interface Props {
  movieSlug: string
}
const props = defineProps<Props>()
const route = useRoute()
const { locale } = useI18n()
const localeRoute = useLocaleRoute()
const getRouteBaseName = useRouteBaseName()

if (getRouteBaseName(route) === ROUTE_NAMES.MOVIE) {
  const redirect = localeRoute(
    {
      name: ROUTE_NAMES.MOVIE_PROGRAM,
      params: { movieSlug: props.movieSlug },
    },
    locale.value
  )

  if (redirect) {
    navigateTo(redirect)
  }
}
</script>
