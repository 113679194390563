export const PROGRAM_LAYOUT = {
  GROUPED_BY_MOVIE: 'grouped-by-movie',
  GROUPED_BY_DAY: 'grouped-by-day',
  CARDS_HORIZONTAL: 'cards-horizontal',
  CARDS_VERTICAL: 'cards-vertical',
  CAROUSEL: 'carousel',
  MOVIE_LIST: 'movie-list',
  SHOW_LIST: 'show-list',
} as const

export const PROGRAM_GROUPED_BY_DAY_LAYOUT = {
  MOVIES: 'movies',
  SHOWS: 'shows',
} as const
