export const GALLERY_IMAGE_SIZE = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
} as const

export const GALLERY_IMAGE_FORMAT = {
  ROUNDED: 'rounded',
  SQUARE: 'square',
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
  ORIGINAL: 'original',
  WIDESCREEN: 'widescreen',
} as const

export const GALLERY_PATTERN = {
  EVEN: 'even',
  ALTERNATE: 'alternate',
  MIXED: 'mixed',
  AUTO: 'auto',
} as const

export const GALLERY_SLIDE_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
} as const
