<template>
  <div
    class="@container/image border-neutral/50 from-neutral-light-1 to-neutral-light-2 text-neutral dark:from-neutral-dark-1 dark:to-neutral-dark-2 dark:text-neutral flex h-full w-full items-center justify-center border bg-gradient-to-br"
    :class="roundedClass"
  >
    <InlineSvg
      :name="icon"
      class="fill-current dark:fill-current"
      :class="iconClasses"
    />
  </div>
</template>

<script setup lang="ts">
interface Props {
  icon: SvgIcon
  rounded?: RoundedTypes
  classes?: string
}

const {
  icon,
  rounded = ROUNDED_TYPE.NONE,
  classes = undefined,
} = defineProps<Props>()

const roundedClass = computed(() => ({
  'rounded-lg': rounded === ROUNDED_TYPE.LG,
  'rounded-full': rounded === ROUNDED_TYPE.FULL,
}))

const iconClasses = computed(() => classes || 'size-8 @lg/image:size-20')

defineOptions({
  name: 'PlaceholderImage',
})
</script>
