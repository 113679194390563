<template>
  <div ref="target" class="flex w-full justify-center">
    <Button
      :theme="BUTTON_THEME.SECONDARY"
      :loading="pending"
      :text="t('btn.showMore')"
    />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  pending: boolean
  enablePaginationOnScroll?: boolean
}

const { enablePaginationOnScroll, pending } = defineProps<Props>()
const emit = defineEmits(['fetch-on-scroll'])

const target = ref(null)
const targetIsVisible = useElementVisibility(target)

const { t } = useI18n()

watch(targetIsVisible, (isVisible) => {
  if (isVisible && enablePaginationOnScroll && !pending) {
    emit('fetch-on-scroll')
  }
})

defineOptions({
  name: 'FetchMoreButton',
})
</script>

<i18n>
de:
  btn:
    showMore: "Mehr laden"
es:
  btn:
    showMore: "Mostrar más"
</i18n>
