export default function useMessage() {
  const messages = useMessageState()

  function getMessage(id: string) {
    return messages.value.find((m: Message) => m.id === id)
  }

  function add(message: Partial<Message>) {
    const id = message.id ?? checksum(JSON.stringify(message))

    if (getMessage(id)) {
      return
    }

    messages.value.push({
      ...message,
      id,
    } as Message)
  }

  function remove(m: Message) {
    messages.value = messages.value.filter(
      (message: Message) => message.id !== m.id
    )
  }

  return {
    messages,
    add,
    remove,
  }
}
