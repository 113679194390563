<template>
  <PageGrid :layout="{ sm: PAGE_GRID_LAYOUT.COLS_1_2 }">
    <PageGridColumn class="sm:order-2">
      <MovieDescription :movie="movie" />
    </PageGridColumn>
    <PageGridColumn class="sm:order-1">
      <MovieShortInfo :movie="movie" :disable-links="true" />
    </PageGridColumn>
    <PageGridColumn class="sm:order-3 sm:col-span-2">
      <PageSectionHeading :title="t('cast')" />

      <PersonList :hide-links="true" :persons="cast" />
    </PageGridColumn>
    <PageGridColumn v-if="slides.length" class="sm:order-4 sm:col-span-2">
      <PageSectionHeading :title="t('gallery')" />
      <GalleryHorizontal
        :slides="slides"
        :image-size="GALLERY_IMAGE_SIZE.LG"
        :image-config="appConfig.images.moviePage.mediaGallery"
      />
    </PageGridColumn>
  </PageGrid>
</template>

<script setup lang="ts">
import type { Movie } from '#gql/default'

interface Props {
  movie: Movie
  uuid: string
}

const props = defineProps<Props>()
const { t } = useI18n()

const { movie } = await useShowGroup({
  operation: 'FetchShowGroupForMovieInfosPage',
  variables: ref({ uuid: props.uuid }),
})

const appConfig = useAppConfig()
const { cast } = useShowCastGallery(movie.value)

const slides = computed(() =>
  movie.value.sceneImages.map((_, index) => ({
    type: GALLERY_SLIDE_TYPE.IMAGE,
    image: getImage(
      movie.value,
      `sceneImages[${index}]`,
      t('movie.sceneImage', {
        movie: movie.value.title,
        index: index + 1,
      })
    ),
  }))
)

usePageMetas({
  title: `${t('pageTitle.infos')} | ${props.movie.title}`,
  ...props.movie.meta,
})

const { $gtm } = useNuxtApp()
onMounted(() => {
  $gtm.trackPageView()
})

defineI18nRoute({
  paths: {
    en: '/movie/[movieSlug]/infos',
    de: '/film/[movieSlug]/filminfos',
  },
})
</script>

<i18n>
de:
  pageTitle:
    infos: "Filminfos"
  movie:
    sceneImage: "Szenebild von {movie}"
  gallery: "Galerie"
  cast: "Cast"
es:
  pageTitle:
    infos: "Información de la película"
  movie:
    sceneImage: "Imagen de escena de {movie}"
  gallery: "Galería"
  cast: "Reparto"
</i18n>
