export const BUTTON_TYPE = {
  RESET: 'reset',
  SUBMIT: 'submit',
  BUTTON: 'button',
} as const

export const BUTTON_THEME = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  TRANSPARENT: 'transparent',
} as const

export const BUTTON_SIZE = {
  XS: 'xs',
  SM: 'sm',
  BASE: 'base',
  LG: 'lg',
} as const
