<template>
  <div data-person-list>
    <component
      :is="!hideLinks ? NuxtLink : 'span'"
      v-for="person in visiblePersons"
      :key="person.name"
      v-bind="
        hideLinks ? {} : { to: getLink(person), class: 'hover:underline' }
      "
      class="inline-flex text-base after:mr-2 after:inline-flex after:content-[','] last-of-type:after:content-['']"
      :class="{
        'hover:underline': !hideLinks,
      }"
    >
      {{ person.name }}
    </component>
    <button
      v-if="isMinimized"
      :title="t('btn.more')"
      type="button"
      class="text-neutral-dark-1 dark:text-neutral-light-1 block hover:underline"
      @click="isMinimized = false"
      v-text="t('btn.more')"
    />
  </div>
</template>

<script lang="ts" setup>
import type { Person } from '#gql/default'

interface Props {
  persons: Person[]
  hideLinks?: boolean
  limit?: number
}

const props = defineProps<Props>()
const localeRoute = useLocaleRoute()
const { t } = useI18n()

function getLink(person: Person) {
  return localeRoute({
    name: ROUTE_NAMES.PERSON_PAGE,
    params: {
      personSlug: person.urlSlug,
    },
  })
}

const isMinimized = ref(props.limit && props.persons.length > props.limit)
const visiblePersons = computed(() =>
  isMinimized.value ? props.persons.slice(0, props.limit) : props.persons
)

const NuxtLink = resolveComponent('NuxtLink')

defineOptions({
  name: 'PersonList',
})
</script>

<i18n>
de:
  btn:
    more: 'mehr anzeigen'
es:
  btn:
    more: 'mostrar más'
</i18n>
