<template>
  <transition
    enter-active-class="duration-300 transition-opacity ease-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    :leave-active-class="leaveActiveClasses"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <slot />
  </transition>
</template>

<script setup lang="ts">
interface Props {
  leaveDuration?: string
}

const props = withDefaults(defineProps<Props>(), {
  leaveDuration: 'duration-200',
})

defineOptions({
  name: 'TransitionFade',
})

const leaveActiveClasses = [
  'transition-opacity ease-in',
  props.leaveDuration,
].join(' ')
</script>
