<template>
  <slot :filter-options :pending />
</template>

<script lang="ts" setup>
export interface FiltersDataProviderProgramFetchParams {
  cinemaIds?: string[]
  showGroups?: string[]
}

interface Props {
  urlPrefix?: string
  filters?: UrlFilter[]
  fetchParams: FiltersDataProviderProgramFetchParams
}

const COMPONENT_NAME = 'FiltersDataProviderProgram'
const props = withDefaults(defineProps<Props>(), {
  filters: () => [],
  urlPrefix: '',
})

const { locale } = useI18n()
useGqlHeaders({ 'Accept-Language': locale.value })

const variables = ref({
  cinemaIds: props.fetchParams.cinemaIds,
  showGroups: props.fetchParams.showGroups ?? undefined,
  filters: props.filters,
})

const { data, execute, refresh, pending } = await useAsyncGql(
  'FetchProgramFilters',
  variables,
  {
    immediate: false,
  }
)

await execute()

watch(
  () => props.fetchParams,
  async () => {
    await refresh()
  }
)

const filterOptions = computed(
  () => data.value?.programByMovie?.filterOptions ?? []
)

defineOptions({
  name: COMPONENT_NAME,
})
</script>
