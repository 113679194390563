export interface RedirectErrorParams {
  title?: string
  message: string
  redirect: {
    name: RouteNames
    params?: Record<string, string | number>
  }
  cinemaId?: string
  severity?: MessageSeverity
  type?: MessageType
}

export default function useRedirectError() {
  const { add: addMessage } = useMessage()
  const localeRoute = useLocaleRoute()
  const router = useRouter()
  const { fetchCinemaStrings, ct } = useCinemaStrings()

  async function redirectWithError(params: RedirectErrorParams) {
    const { title = '', message = '' } = params

    if (!params.redirect && !message) {
      return
    }

    await fetchCinemaStrings({
      cinemaId: params.cinemaId,
      keys: [message, title, REDIRECT_ERROR_STRINGS.UNEXPECTED_ERROR].filter(
        Boolean
      ),
    })

    addMessage({
      title: ct(title, null, undefined),
      message: ct(message, null, ct(REDIRECT_ERROR_STRINGS.UNEXPECTED_ERROR)),
      severity: params.severity ?? MESSAGE_SEVERITY.INFO,
      type: params.type ?? MESSAGE_TYPE.TOAST,
    })

    const route = computed(() => localeRoute(params.redirect))

    if (route.value) {
      router.replace(route.value)
    }
  }

  return {
    redirectWithError,
  }
}
