<template>
  <transition v-bind="transitionClassObject">
    <slot />
  </transition>
</template>

<script lang="ts" setup>
interface Props {
  from?: typeof TRANSITION_POSITIONS.BOTTOM | typeof TRANSITION_POSITIONS.CENTER
  to?: typeof TRANSITION_POSITIONS.BOTTOM | typeof TRANSITION_POSITIONS.CENTER
}

const props = withDefaults(defineProps<Props>(), {
  from: TRANSITION_POSITIONS.BOTTOM,
  to: TRANSITION_POSITIONS.CENTER,
})

const transitionClassObject = computed(() => {
  const activeClass = 'transition-transform duration-300'
  const visibleClass = 'scale-100 translate-x-0 translate-y-0'
  const hiddenClass = 'scale-0'

  return {
    enterFromClass: `${hiddenClass} ${
      props.from === TRANSITION_POSITIONS.BOTTOM ? 'translate-y-full' : ''
    }`,
    enterActiveClass: `${activeClass} ${
      props.from === TRANSITION_POSITIONS.BOTTOM ? 'origin-bottom' : ''
    }`,
    enterToClass: visibleClass,
    leaveFromClass: visibleClass,
    leaveActiveClass: `${activeClass} ${
      props.to === TRANSITION_POSITIONS.BOTTOM ? 'origin-bottom' : ''
    }`,
    leaveToClass: `${hiddenClass} ${
      props.to === TRANSITION_POSITIONS.BOTTOM ? 'translate-y-full' : ''
    }`,
  }
})
</script>

<script lang="ts">
export default {
  name: 'TransitionScale',
}
</script>
