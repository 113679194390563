<template>
  <div data-catalog-program class="space-y-6">
    <LazyFilters v-if="filters?.length" v-bind="filterProps" />

    <Suspense>
      <div class="space-y-6">
        <template v-if="!hasPreviousPages && pending">
          <LazyProgramGroupedByDay
            v-if="layout === GROUPED_BY_DAY || layout === SHOW_LIST"
            :layout="
              layout === SHOW_LIST
                ? PROGRAM_GROUPED_BY_DAY_LAYOUT.SHOWS
                : PROGRAM_GROUPED_BY_DAY_LAYOUT.MOVIES
            "
            :render-skeletons
          />
          <LazyProgramGroupedByMovie
            v-else-if="layout === GROUPED_BY_MOVIE"
            :render-skeletons
          />
          <LazyMovieCardsHorizontal
            v-else-if="layout === CARDS_HORIZONTAL"
            :render-skeletons
          />
          <LazyMovieCardsVertical
            v-else-if="layout === CARDS_VERTICAL"
            :render-skeletons
          />
          <LazyMovieList v-else-if="layout === MOVIE_LIST" :render-skeletons />
          <LazyCarouselSkeleton v-else-if="layout === CAROUSEL" />
        </template>

        <template v-else-if="hasPreviousPages || !pending">
          <EmptyResultHandler
            v-if="!pending && (hasFallbackResults || !groups?.length)"
            :type="EMPTY_RESULT_TYPE.MOVIES"
            :has-filters-applied
            :url-prefix
            :has-fallback-results
            class="mt-4"
          />

          <LazyProgramGroupedByDay
            v-if="layout === GROUPED_BY_DAY || layout === SHOW_LIST"
            :groups="groupedByDay"
            :hide-date
            :show-cinema-names
            :show-playtimes-only
            :layout="
              layout === SHOW_LIST
                ? PROGRAM_GROUPED_BY_DAY_LAYOUT.SHOWS
                : PROGRAM_GROUPED_BY_DAY_LAYOUT.MOVIES
            "
          />
          <LazyProgramGroupedByMovie
            v-else-if="layout === GROUPED_BY_MOVIE"
            :groups="groupedByMovie"
            :show-cinema-names
            :playtimes-layout
          />
          <LazyMovieCardsHorizontal
            v-else-if="layout === CARDS_HORIZONTAL"
            :movies
            :link-params
          />
          <LazyMovieCardsVertical
            v-else-if="layout === CARDS_VERTICAL"
            :movies
            :link-params
          />
          <LazyMovieCarousel
            v-else-if="layout === CAROUSEL"
            :render-above-the-fold
            :movies
            :link-params
          />
          <LazyMovieList
            v-else-if="layout === MOVIE_LIST"
            :movies="movies"
            :hide-date
            :link-params
          />

          <FetchMoreButton
            v-if="showFetchMoreButton"
            :pending
            :enable-pagination-on-scroll
            class="col-span-full"
            @click="fetchMore"
            @fetch-on-scroll="fetchMore"
          />
        </template>
      </div>
      <template #fallback>
        <LazyProgramGroupedByDay
          v-if="layout === GROUPED_BY_DAY || layout === SHOW_LIST"
          :layout="
            layout === SHOW_LIST
              ? PROGRAM_GROUPED_BY_DAY_LAYOUT.SHOWS
              : PROGRAM_GROUPED_BY_DAY_LAYOUT.MOVIES
          "
          :render-skeletons
        />
        <LazyProgramGroupedByMovie
          v-else-if="layout === GROUPED_BY_MOVIE"
          :render-skeletons
        />
        <LazyMovieCardsHorizontal
          v-else-if="layout === CARDS_HORIZONTAL"
          :render-skeletons
        />
        <LazyMovieCardsVertical
          v-else-if="layout === CARDS_VERTICAL"
          :render-skeletons
        />
        <LazyMovieList v-else-if="layout === MOVIE_LIST" :render-skeletons />
        <LazyCarouselSkeleton v-else-if="layout === CAROUSEL" />
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts" setup>
import type {
  LinkParam,
  ProgramGroupByDay,
  ProgramGroupByMovie,
} from '#gql/default'

export interface ProgramQueryFilters {
  cinemaIds: string[]
  dates?: string[]
  genres?: string[]
  periods?: string[]
  flags?: string[]
  languageFlags?: string[]
  technologyFlags?: string[]
  eventFlags?: string[]
  miscellaneousFlags?: string[]
  contentRatings?: string[]
  showGroups?: string[]
  auditoriums?: string[]
  search?: string
}

interface Props {
  layout: ProgramLayout
  filters?: UrlFilter[]
  linkParams: LinkParam[]
  urlPrefix?: string
  fetchFallbackResults?: boolean
  preserveFilters: boolean
  showPlaytimesOnly?: boolean
  bookingModal: boolean
  itemsPerPage?: number
  enablePagination?: boolean
  enablePaginationOnScroll?: boolean
  renderAboveTheFold: boolean
  showFallbackResults?: boolean
  hideDate?: boolean
}

const props = defineProps<Props & ProgramQueryFilters>()
const route = useRoute()
const { t } = useI18n()

const {
  GROUPED_BY_DAY,
  GROUPED_BY_MOVIE,
  CARDS_HORIZONTAL,
  CARDS_VERTICAL,
  CAROUSEL,
  MOVIE_LIST,
  SHOW_LIST,
} = PROGRAM_LAYOUT

const fetchOperation = computed(() => {
  if (props.layout === GROUPED_BY_DAY) {
    return 'FetchProgramByDay'
  } else if (props.layout === GROUPED_BY_MOVIE) {
    return 'FetchProgramByMovie'
  } else if (props.layout === CAROUSEL) {
    return 'FetchProgramForMovieCarousel'
  } else if (props.layout === MOVIE_LIST) {
    return 'FetchProgramForMovieList'
  } else if (props.layout === SHOW_LIST) {
    return 'FetchProgramForShowsList'
  }

  return 'FetchProgramForMovieCards'
})

const showFetchMoreButton = computed(
  () =>
    props.enablePagination &&
    hasMorePages.value &&
    props.layout !== CARDS_HORIZONTAL &&
    props.layout !== CAROUSEL
)
const showCinemaNames = computed(() => props.cinemaIds.length > 1)
const renderSkeletons = computed(() => props.itemsPerPage ?? 4)
const playtimesLayout = computed(() =>
  route.query.dates ? PLAYTIMES_LAYOUT.VERTICAL : PLAYTIMES_LAYOUT.HORIZONTAL
)

const groupedByDay = computed(
  () => groups.value as unknown as ProgramGroupByDay[]
)
const groupedByMovie = computed(
  () => groups.value as unknown as ProgramGroupByMovie[]
)
const movies = computed(() =>
  (groups.value as unknown as ProgramGroupByMovie[])?.flatMap(
    ({ movie, urlSlug }) => ({
      ...movie,
      urlSlug,
    })
  )
)

const {
  results: groups,
  hasFallbackResults,
  pending,
  hasPreviousPages,
  hasFiltersApplied,
  hasMorePages,
  fetchMore,
} = await useProgram({
  fetchOperation: fetchOperation.value,
  filters: props.filters,
  urlPrefix: props.urlPrefix,
  fetchFallbackResults: props.fetchFallbackResults,
  preselected: {
    cinemaIds: props.cinemaIds,
    dates: props.dates,
    genres: props.genres,
    periods: props.periods,
    languageFlags: props.languageFlags,
    technologyFlags: props.technologyFlags,
    eventFlags: props.eventFlags,
    miscellaneousFlags: props.miscellaneousFlags,
    contentRatings: props.contentRatings,
    showGroups: props.showGroups,
    auditoriums: props.auditoriums,
    search: props.search,
    flags: props.flags,
  },
  first: props.itemsPerPage,
})

// @TODO should be refactored into a useFilters composable
const filterProps = computed(() => ({
  urlPrefix: props.urlPrefix,
  fetchComponent: resolveComponent('FiltersDataProviderProgram'),
  filters: props.filters,
  searchPlaceholder: t('placeholder.search'),
  fetchParams: {
    cinemaIds: props.cinemaIds,
    showGroups: props.showGroups ?? undefined,
  },
}))

defineOptions({
  name: 'CatalogProgram',
})
</script>

<i18n>
de:
  placeholder:
    search: "Film suchen"
es:
  placeholder:
    search: "Buscar película"
</i18n>
