export const MESSAGE_SEVERITY = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
} as const

export const MESSAGE_TYPE = {
  TOAST: 'toast',
  MODAL: 'modal',
} as const
