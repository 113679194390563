export const CONSENT_MANAGER_PURPOSE = {
  REQUIRED: 'REQUIRED',
  FUNCTIONAL: 'FUNCTIONAL',
  ADVERTISING: 'ADVERTISING',
} as const

export const CONSENT_MANAGER_MODE = {
  VENDOR_SELECTION: 'VENDOR_SELECTION',
  PURPOSE_SELECTION: 'PURPOSE_SELECTION',
} as const

export const CONSENT_MANAGER_BUTTONS = {
  ACCEPT_ALL: 'ACCEPT_ALL',
  CUSTOMIZE: 'CUSTOMIZE',
  DENY_ALL: 'DENY_ALL',
} as const
