<template>
  <div
    data-gallery-horizontal
    class="group-data-[default-horizontal-spacing=true]:container-bleed space-y-4"
  >
    <HorizontalMediaScroller
      :slides="slides"
      :per-page="slidesVisible"
      image-selector="[data-image]"
      :active-slide="activeSlide"
      :classes="{
        buttonPrev: cssClasses.buttonPrev,
        buttonNext: cssClasses.buttonNext,
        slide: cssClasses.slide,
      }"
    >
      <template #default="{ slide: slide, index }">
        <GallerySlide
          :slide="slide"
          :hide-title="hideTitle"
          :image-format="imageFormat"
          :image-config="getImageConfig()"
          :image-placeholder="imagePlaceholder"
          :hide-description="hideDescription"
          :render-trailer="renderTrailer"
          :allow-zoom="allowZoom"
          :index="index"
          :classes="{
            image: cssClasses.image,
            container: cssClasses.container,
            title: cssClasses.title,
            description: cssClasses.description,
          }"
          @click="handleSlideClick(index)"
        />
      </template>
    </HorizontalMediaScroller>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

export interface GalleryHorizontalProps {
  imageSize?: GalleryImageSize
  imageFormat?: GalleryImageFormat
  imageConfig?: ImageConfig
  renderAboveTheFold?: boolean
  renderTrailer?: boolean
  hideTitle?: boolean
  hideDescription?: boolean
  hideLink?: boolean
  allowZoom?: boolean
  slides: GallerySlide[]
  slidesPerPage?: number
  activeSlide?: number
  imagePlaceholder?: ImagePlaceholder
  classes?: {
    title?: string | object | []
    container?: string | object | []
    description?: string | object | []
    image?: string | object | []
    buttonPrev?: string | object | []
    buttonNext?: string | object | []
    slide?: string | object | []
  }
}

const props = withDefaults(defineProps<GalleryHorizontalProps>(), {
  imageSize: GALLERY_IMAGE_SIZE.XS,
  imageFormat: GALLERY_IMAGE_FORMAT.LANDSCAPE,
  imageConfig: undefined,
  renderAboveTheFold: false,
  renderTrailer: false,
  hideTitle: false,
  hideDescription: false,
  hideLink: false,
  allowZoom: false,
  slidesPerPage: undefined,
  activeSlide: 0,
  imagePlaceholder: undefined,
  classes: () => ({}),
})

const emit = defineEmits(['slide-click'])

const cssClasses = computed(() => {
  const {
    title,
    description,
    image,
    buttonNext,
    buttonPrev,
    slide,
    container,
  } = props.classes

  return {
    title:
      title ??
      'font-medium mt-1 leading-tight text-sm text-center text-ellipsis overflow-hidden',
    description:
      description ??
      'text-xs leading-tight text-center text-ellipsis overflow-hidden',
    image: image ?? { 'cursor-pointer': props.allowZoom },
    buttonPrev:
      buttonPrev ??
      'hidden sm:flex absolute left-0 md:left-3 xl:left-5 z-20 -translate-y-1/2',
    buttonNext:
      buttonNext ??
      'hidden sm:flex absolute right-0 md:right-3 xl:right-5 z-20 -translate-y-1/2',
    slide: slide ?? [
      'flex flex-col grow-0 shrink-0 overflow-hidden px-1.5 sm:px-2',
      {
        'basis-1/4 sm:basis-1/5 md:basis-1/6':
          props.imageSize === GALLERY_IMAGE_SIZE.XS,
        'basis-1/3 sm:basis-1/4': props.imageSize === GALLERY_IMAGE_SIZE.SM,
        'basis-1/2 sm:basis-1/3': props.imageSize === GALLERY_IMAGE_SIZE.MD,
        'basis-full': props.imageSize === GALLERY_IMAGE_SIZE.LG,
        'sm:basis-1/2':
          props.imageSize === GALLERY_IMAGE_SIZE.LG && slidesVisible.value > 1,
      },
    ],
    container: container ?? '',
  }
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const current = breakpoints.current()

const slidesVisible = computed(() => {
  if (props.slidesPerPage) {
    return props.slidesPerPage
  }

  let perPage = 1

  switch (props.imageSize) {
    case GALLERY_IMAGE_SIZE.LG:
      perPage = current.value?.includes('sm') ? 2 : 1
      break
    case GALLERY_IMAGE_SIZE.MD:
      perPage = current.value?.includes('sm') ? 3 : 2
      break
    case GALLERY_IMAGE_SIZE.SM:
      perPage = current.value?.includes('sm') ? 4 : 3
      break
    case GALLERY_IMAGE_SIZE.XS:
      if (current.value?.includes('md')) {
        perPage = 6
      } else {
        perPage = current.value?.includes('sm') ? 5 : 4
      }
      break
  }

  return perPage
})

const appConfig = useAppConfig()
function getImageConfig() {
  if (props.imageConfig) {
    return props.imageConfig
  }

  let sizes

  if (GALLERY_IMAGE_SIZE.XS) {
    sizes = '104px lg:157px xlg:115px'
  }

  if (GALLERY_IMAGE_SIZE.SM) {
    sizes = '104px lg:157px xlg:115px'
  }

  if (GALLERY_IMAGE_SIZE.MD) {
    sizes = '104px lg:157px xlg:115px'
  }

  if (GALLERY_IMAGE_SIZE.LG) {
    sizes = '584px sm:273px md:337px lg:465px xlg:528px'
  }

  return {
    ...appConfig.images.gallery.horizontal,
    sizes,
  }
}

function handleSlideClick(index: number) {
  emit('slide-click', index)
}

defineOptions({
  name: 'GalleryHorizontal',
})
</script>
