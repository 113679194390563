<template>
  <div
    v-if="facts"
    class="text-dark dark:text-light inline-block align-baseline text-sm"
    v-html="facts"
  />
</template>

<script setup lang="ts">
import type { Movie } from '#gql/default'

interface Props {
  movie: Movie
}

const props = defineProps<Props>()

const facts = computed(() => {
  const { duration, genres, contentRating } = props.movie

  return [
    duration ? `${duration} min` : null,
    genres
      ?.slice(0, 3)
      .map((genre) => genre.name)
      .join(', '),
    formatContentRating(contentRating),
  ]
    .filter(Boolean)
    .join('<span class="text-primary"> | </span>')
})

defineOptions({
  name: 'MovieFacts',
})
</script>
