import type { Show } from '#gql/default'

export default async function useShow(variables: {
  id?: string
  urlSlug?: string
  cinemaId?: string
}) {
  const { data, status, error, execute } = await useAsyncData(
    'FetchShow',
    () => GqlFetchShow(variables),
    { immediate: false }
  )

  const show = computed(() => data.value?.show as Show | undefined)
  const pending = computed(() => status.value === 'pending')
  const errorMessage = computed(
    () => error.value?.cause?.gqlErrors?.[0]?.extensions?.cinemaString
  )

  await execute()

  return {
    show,
    pending,
    error: errorMessage,
  }
}
