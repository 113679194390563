<template>
  <div :class="cssClasses.container">
    <div
      v-if="!isLoaded"
      :class="cssClasses.placeholder"
      :style="`background: linear-gradient(-60deg, ${image.colors?.join(
        ','
      )}), no-repeat center center scroll`"
    />
    <NuxtImg
      v-if="!isFallbackVisible && imageUrl"
      ref="imageRef"
      v-bind="{
        ...config,
        format: config.format ?? 'webp',
        src: imageUrl.origin + imageUrl.pathname,
        alt: image.alt,
        width: useImageDimension ? image.width : config.width,
        height: useImageDimension ? image.height : config.height,
      }"
      class="transition-opacity duration-200"
      :modifiers="modifiers"
      :class="[
        cssClasses.image,
        {
          'opacity-0': !isLoaded,
          'opacity-100': isLoaded,
        },
      ]"
      :fit="IMAGE_FIT.COVER"
      @load="isLoaded = true"
      @error="isFallbackVisible = true"
    />

    <slot
      v-if="isFallbackVisible || !imageUrl"
      :class-list="['absolute inset-0']"
    />
  </div>
</template>

<script setup lang="ts">
/**
 * TODO: The load and error events stopped working with nuxt3
 * https://github.com/nuxt/image/issues/412
 * https://github.com/nuxt/image/issues/682
 *
 * lets hope it gets fixed soon with
 */

interface Props {
  config: ImageConfig
  image: ImageObject
  imageFormat?: ImageFormat
  classes?: {
    container?: string | object | []
    image?: string | object | []
    placeholder?: string | object | []
  }
}

const props = withDefaults(defineProps<Props>(), {
  classes: () => ({}),
  imageFormat: undefined,
})

const useImageDimension = computed(
  () => !props.config.width && !props.config.height
)

const imageRef = ref()
const imageUrl = computed<URL | undefined>(() => {
  if (!props.image || !props.image.src) {
    return undefined
  }

  try {
    return new URL(props.image.src)
  } catch {
    return undefined
  }
})

// TODO: maybe we need some sort of whitelist which modifiers are allowed, but for now only the heroImageFallback (poster image uses the blur param in the url)
const modifiers = computed<Record<string, string>>(() => {
  let params: Record<string, string> = {}

  if (imageUrl.value) {
    const searchParams = new URLSearchParams(imageUrl.value.search)
    for (const [key, value] of searchParams.entries()) {
      params[key] = value
    }
  }

  if (props.config.modifiers) {
    params = { ...params, ...props.config.modifiers }
  }

  return params
})

/**
 * this workaround is required until https://github.com/nuxt/image/pull/842 is merged
 * after the new nuxt-image version the imageRef and onMounted part can be removed
 */
onMounted(() => {
  if (!imageRef.value) {
    isFallbackVisible.value = true
    return
  }

  const element = imageRef.value.$el as HTMLImageElement

  if (element) {
    element.onerror = () => {
      isFallbackVisible.value = true
    }
  }

  nextTick(() => {
    if (!element || !element.complete) {
      return
    }

    isLoaded.value = true

    if (element.naturalWidth === 0) {
      isFallbackVisible.value = true
    }
  })
})

const cssClasses = computed(() => {
  const { placeholder, container, image } = props.classes

  return {
    placeholder: placeholder ?? 'w-full h-full',
    container: container ?? '',
    image: image ?? 'w-full h-auto',
  }
})

const isFallbackVisible = ref(false)
const isLoaded = ref(props.config?.preload ?? false)

defineOptions({
  name: 'ImageWrapper',
})
</script>
