<template>
  <PageGrid>
    <PageGridColumn>
      <CatalogProgram
        :filters="[
          URL_FILTER.DATES,
          URL_FILTER.LANGUAGE,
          URL_FILTER.TECHNOLOGY,
          URL_FILTER.MISCELLANEOUS,
          URL_FILTER.EVENT,
          URL_FILTER.AUDITORIUMS,
          URL_FILTER.PERIODS,
          URL_FILTER.CINEMA_IDS,
        ]"
        :layout="PROGRAM_LAYOUT.GROUPED_BY_DAY"
        :items-per-page="10"
        :show-groups="[movieSlug]"
        :show-playtimes-only="true"
        :cinema-ids="config?.cinemas.map(({ id }) => id)"
        :fetch-fallback-results="false"
        :link-params="[]"
        :preserve-filters="true"
        :booking-modal="false"
        :render-above-the-fold="false"
      />
    </PageGridColumn>
  </PageGrid>
</template>

<script lang="ts" setup>
import { useRouteParams } from '@vueuse/router'
import type { Movie } from '#gql/default'

interface Props {
  movie: Movie
  uuid: string
}

const { movie } = defineProps<Props>()
const movieSlug = useRouteParams('movieSlug', '', { transform: String })
const { config } = useCms()

const { t } = useI18n()

usePageMetas({
  title: `${t('pageTitle.program')} | ${movie.title}`,
  ...movie.meta,
})

const { $gtm } = useNuxtApp()
onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'MoviePlaytimes',
})

defineI18nRoute({
  paths: {
    en: '/movie/[movieSlug]/program',
    de: '/film/[movieSlug]/spielzeiten',
  },
})
</script>

<i18n>
de:
  pageTitle:
    program: "Spielzeiten"
</i18n>
