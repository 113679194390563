export const URL_FILTER = {
  SEARCH: 'search',
  GENRES: 'genres',
  CONTENT_RATINGS: 'contentRatings',
  FLAGS: 'flags',
  PERIODS: 'periods',
  CITY: 'city',
  LANGUAGE: 'languageFlags',
  TECHNOLOGY: 'technologyFlags',
  MISCELLANEOUS: 'miscellaneousFlags',
  FEATURE_FLAGS: 'featureFlags',
  EVENT: 'eventFlags',
  AUDITORIUMS: 'auditoriums',
  SHOW_GROUPS: 'showGroups',
  DATE: 'date', // deprecated
  DATES: 'dates',
  TIMES_OF_DAY: 'timesOfDay',
  TYPES: 'types',
  CINEMA_IDS: 'cinemaIds',
  CINEMA_ID: 'cinemaId',
  PAGE: 'page',
} as const
