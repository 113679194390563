import { useRouteQuery } from '@vueuse/router'

let pending = false

export default async function useCity({
  urlPrefix,
  preselectedCitySlug,
}: {
  urlPrefix?: string
  preselectedCitySlug?: string
} = {}) {
  const citySlug = useRouteQuery(
    urlPrefix ? `${urlPrefix}_${URL_FILTER.CITY}` : URL_FILTER.CITY,
    preselectedCitySlug
  )
  const cityState = useCityState()
  const cityKey = urlPrefix ?? 'default'
  const city = computed(() => cityState.value[cityKey])

  const { data, execute, refresh } = await useAsyncData(
    getGqlFetchKey('UseCity', citySlug.value),
    () =>
      GqlFetchCity({
        urlSlug: citySlug.value as string,
      }),
    {
      immediate: false,
    }
  )

  if (!city.value && citySlug.value && !pending) {
    pending = true
    await execute()
    cityState.value = {
      ...cityState.value,
      [urlPrefix ?? 'default']: data.value?.city ?? undefined,
    }
    pending = false
  }

  function resetCity() {
    cityState.value[cityKey] = null
  }

  watch(
    () => citySlug.value,
    async (newSlug) => {
      if (newSlug && newSlug !== city.value?.urlSlug) {
        if (!pending) {
          pending = true
          await refresh()
          cityState.value = {
            ...cityState.value,
            [cityKey]: data.value?.city ?? undefined,
          }
          pending = false
        }
      } else {
        cityState.value = {
          ...cityState.value,
          [cityKey]: undefined,
        }
      }
    },
    { immediate: false }
  )

  return {
    city,
    resetCity,
  }
}
