<template>
  <div class="relative" :data-youtube-player="playerId">
    <ConsentManagerWarning
      v-if="!hasConsent"
      :can-be-activated="true"
      :foreign-domains="['www.youtube.com']"
      class="absolute inset-0 z-10 h-full w-full"
    />

    <div ref="target" class="aspect-[2/1] h-full w-full">
      <div :id="playerId" class="h-full w-full" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import YouTubePlayer from 'youtube-player'

export interface YoutubePlayerProps {
  remoteVideoId: string
  autoplay?: boolean
  mute?: boolean
  controls?: boolean
  pause?: boolean
}

const { remoteVideoId, autoplay, mute, controls, pause } = withDefaults(
  defineProps<YoutubePlayerProps>(),
  {
    autoplay: true,
    controls: true,
    render: false,
    mute: true,
    pause: false,
  }
)

const { locale } = useI18n()
const { getConsentForDomain } = useConsentManager()
const hasConsent = computed(() => getConsentForDomain('www.youtube.com'))
const playerId = computed(() => `player-${remoteVideoId}-${Math.random()}`)
const player = ref()

function initYoutubePlayer() {
  player.value = YouTubePlayer(playerId.value, {
    videoId: remoteVideoId,
    playerVars: {
      autoplay: autoplay ? 1 : 0,
      controls: controls ? 1 : 0,
      hl: locale.value,
      iv_load_policy: 3,
      modestbranding: 1,
      rel: 0,
      enablejsapi: 1,
    },
  })

  player.value.on('ready', () => {
    if (mute) {
      player.value.mute()
    }

    if (autoplay) {
      player.value.playVideo()
    }
  })
}

// @TODO: find a solution to allow swiping carousel when player is loaded. one solution could be to
// use a div overlaying the player and intercept the touch events?
onMounted(async () => {
  await nextTick()

  if (hasConsent.value) {
    initYoutubePlayer()
  }
})

onUnmounted(() => {
  player.value = null
})

watch(
  () => pause,
  (pauseVideo) => {
    if (player.value) {
      if (pauseVideo) {
        player.value.pauseVideo()
      } else {
        player.value.playVideo()
      }
    }
  }
)

watch(hasConsent, () => {
  if (hasConsent.value && !player.value) {
    initYoutubePlayer()
  } else {
    player.value.destroy()
    player.value = null
  }
})

defineOptions({
  name: 'YoutubePlayer',
})
</script>
