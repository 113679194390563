<template>
  <div
    class="relative h-full w-full rounded-lg text-center text-sm font-light"
    data-image
  >
    <YoutubePlayer
      v-if="renderTrailer"
      :remote-video-id="remoteVideoId"
      v-bind="slide.videoPlayerParams"
      class="h-full w-full overflow-hidden rounded-lg"
    />

    <YoutubeThumbnail
      v-else
      :remote-video-id="remoteVideoId"
      :classes="{
        image: [classes.image, 'absolute inset-0 w-full h-full'],
      }"
      @click="handleClick"
    />

    <LazyYoutubeModal
      v-if="isTrailerModalVisible"
      :remote-video-id="remoteVideoId"
      :render-player="true"
      :autoplay="true"
      @close="isTrailerModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
interface Props {
  slide: GallerySlide
  imageFormat: GalleryImageFormat
  imageConfig: ImageConfig
  allowZoom?: boolean
  renderTrailer?: boolean
  index?: number
  classes: {
    title?: string | object | []
    description?: string | object | []
    image?: string | object | []
    container?: string | object | []
  }
}

const props = withDefaults(defineProps<Props>(), {
  index: 0,
  renderTrailer: false,
})

const isTrailerModalVisible = ref(false)
const remoteVideoId = getYoutubeRemoteVideoId(props.slide.videoUrl)

function handleClick() {
  if (props.allowZoom) {
    isTrailerModalVisible.value = true
  }
}

defineOptions({
  name: 'GallerySlideVideo',
})
</script>
