<template>
  <component
    :is="displayLink ? NuxtLink : 'div'"
    v-bind="displayLink ? slide.link : { class: 'contents' }"
    class="text-center text-sm font-light"
  >
    <GalleryImage
      data-image
      :image="slide.image"
      :image-config="imageConfig"
      :image-format="imageFormat"
      :placeholder="imagePlaceholder"
      :class="cssClasses.image"
      @click="handleClick"
    />
  </component>

  <div
    v-if="!hideTitle && slide.title"
    :class="cssClasses.title"
    v-html="slide.title"
  />

  <div
    v-if="!hideDescription && slide.description"
    :class="cssClasses.description"
    v-html="slide.description"
  />

  <LazyImageModal
    v-if="allowZoom && isImageModalVisible"
    v-bind="slide"
    @close="isImageModalVisible = false"
  />
</template>

<script setup lang="ts">
interface Props {
  slide: GallerySlide
  imageFormat: GalleryImageFormat
  imageConfig: ImageConfig
  hideTitle?: boolean
  hideDescription?: boolean
  hideLink?: boolean
  allowZoom?: boolean
  index?: number
  imagePlaceholder?: ImagePlaceholder
  classes: {
    title?: string | object | []
    description?: string | object | []
    image?: string | object | []
    container?: string | object | []
  }
}

const props = defineProps<Props>()

const NuxtLink = resolveComponent('NuxtLink')

const displayLink = computed(
  () => !props.allowZoom && !props.hideLink && props.slide.link?.to
)

const cssClasses = computed(() => {
  const { title, description, image, container } = props.classes

  return {
    title: title ?? 'font-medium break-words pt-3 leading-tight',
    description: description ?? 'break-words leading-tight',
    image: image ?? { 'cursor-pointer': props.allowZoom },
    container: container ?? '',
  }
})

const isImageModalVisible = ref(false)

function handleClick() {
  if (displayLink.value) {
    return
  }

  if (props.allowZoom) {
    isImageModalVisible.value = true
  }
}

defineOptions({
  name: 'GallerySlideImage',
})
</script>
