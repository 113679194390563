import type { ProgramQueryFilters } from '@base/components/catalog/program.vue'
import type {
  FetchProgramByDayQueryVariables,
  FetchProgramByMovieQueryVariables,
  FetchProgramForCinemaShowGroupQueryVariables,
  FetchProgramForMovieCardsQueryVariables,
  FetchProgramForMovieCarouselQueryVariables,
  FetchProgramForMovieListQueryVariables,
  FetchProgramForShowsListQueryVariables,
  ShowPeriod,
} from '#gql/default'

export default async function useProgram({
  fetchOperation,
  fetchFallbackResults,
  filters,
  urlPrefix,
  preselected,
  first = 10,
}: {
  fetchOperation:
    | 'FetchProgramByMovie'
    | 'FetchProgramByDay'
    | 'FetchProgramForCinemaShowGroup'
    | 'FetchProgramForMovieCards'
    | 'FetchProgramForMovieCarousel'
    | 'FetchProgramForMovieList'
    | 'FetchProgramForShowsList'
  fetchFallbackResults: boolean
  filters?: UrlFilter[]
  urlPrefix?: string
  preselected: ProgramQueryFilters
  first?: number
}) {
  const { fetchParams, hasFiltersApplied } = useFetchParams({
    filters,
    urlPrefix,
    preselected,
  })

  const variables: Ref<
    | FetchProgramByDayQueryVariables
    | FetchProgramByMovieQueryVariables
    | FetchProgramForCinemaShowGroupQueryVariables
    | FetchProgramForMovieCardsQueryVariables
    | FetchProgramForMovieCarouselQueryVariables
    | FetchProgramForMovieListQueryVariables
    | FetchProgramForShowsListQueryVariables
  > = ref(getVariables(false, 1))

  const { data, status, error } = await useAsyncGql(fetchOperation, variables)
  const {
    results,
    pending,
    hasMorePages,
    hasPreviousPages,
    hasFallbackResults,
    fetchMore,
  } = useFetchResults({
    data,
    status,
    fetchFallbackResults,
    fetchParams,
    variables,
    getVariables,
  })

  // Merge the URL fetch params with the default values.
  // We have to explicitly cast periods to ShowPeriod because
  // the default value is an array of strings. The query always requires
  // cinemaIds to be set, so we have to include it for the fallback results too
  function getVariables(fallbackResults = false, page = 1) {
    const { cinemaIds, periods, ...otherParams } = fetchParams.value
    const params = fallbackResults
      ? {
          cinemaIds: preselected.cinemaIds,
        }
      : { periods: periods as ShowPeriod[], ...otherParams }

    return {
      cinemaIds: cinemaIds ?? preselected.cinemaIds,
      ...params,
      first,
      page,
    }
  }

  const errorMessage = computed(
    () => error.value?.cause?.gqlErrors?.[0]?.extensions?.cinemaString
  )

  return {
    results,
    pending,
    hasMorePages,
    hasFiltersApplied,
    hasPreviousPages,
    hasFallbackResults,
    fetchMore,
    error: errorMessage,
  }
}
