const isScrollLocked = ref(false)

export default function useScrollLockWithScrollBar() {
  function toggle() {
    isScrollLocked.value = !isScrollLocked.value

    const body = document?.querySelector('body')

    if (isScrollLocked.value) {
      const scrollPosition =
        -document.documentElement.scrollTop || -document.body.scrollTop
      body!.style.setProperty('top', scrollPosition + 'px')
    }

    body!.classList.toggle('w-full')
    body!.classList.toggle('fixed')
    body!.classList.toggle('overflow-y-scroll')
    window.scrollTo(0, -parseInt(body!.style.top, 10))
  }

  function remove() {
    const body = document?.querySelector('body')

    body!.classList.remove('w-full', 'fixed', 'overflow-y-scroll')
  }

  return {
    isScrollLocked,
    toggle,
    remove,
  }
}
