import { useResizeObserver } from '@vueuse/core'

interface useCarouselOptions {
  infinite?: boolean
  autoplay?: boolean
  duration?: number

  threshold?: {
    slide: number
    drag: number
  }
  slides: {
    preload: number
    total: Ref<number>
  }
  slidesVisible: Ref
  preventDefault?: boolean
}

export default function useCarousel({
  slides,
  slidesVisible,
  infinite = false,
  autoplay = false,
  duration = 4000,
  threshold = {
    slide: 30,
    drag: 20,
  },
  preventDefault = false,
}: useCarouselOptions) {
  const slideWidth = ref(0)
  const slidesPerPage = ref(slidesVisible.value ?? 1)
  const slideDuration = ref(duration)
  const isDragging = ref(false)
  const isPlaying = ref(autoplay)
  const lastSlideToPreload = ref(slides.preload)
  const isAnimating = ref(false)
  const currentIndex = ref(0)
  const offset = ref(0)
  const startX = ref(0)
  const endX = ref(0)
  const deltaX = ref(0)
  const prefixSlide = ref(0)
  const suffixSlide = ref(0)

  const autoplayTimeout = ref()
  const sliderWrapperRef = ref()

  const getWrapperRef = (el: HTMLElement) => {
    sliderWrapperRef.value = el
  }

  function onTouchStart(event: TouchEvent) {
    isDragging.value = true
    startX.value = Math.floor(event.touches[0].clientX)
    deltaX.value = 0 // Reset deltaX at the start of a new touch
  }

  function onTouchEnd() {
    isDragging.value = false

    if (deltaX.value > threshold.slide) {
      slideLeft() // Change this to slideLeft
    } else if (deltaX.value < -threshold.slide) {
      slideRight() // Change this to slideRight
    }

    offset.value = 0
    startX.value = 0
    endX.value = 0
    deltaX.value = 0 // Reset deltaX at the end of the touch
  }

  function onTouchMove(event: TouchEvent) {
    if (!isDragging.value) return // Only handle touch move if it's a drag operation

    endX.value = Math.floor(event.touches[0].clientX)
    deltaX.value = endX.value - startX.value // Update deltaX based on the movement of the touch

    // Only update offset if the absolute value of deltaX is greater than the drag threshold
    if (Math.abs(deltaX.value) > threshold.drag) {
      offset.value = -deltaX.value // The offset should be the negative of deltaX
    }
  }

  function slideTo(index: number, withAnimation = true): void {
    isAnimating.value = withAnimation

    currentIndex.value = index

    // wait for the animation to complete
    setTimeout(() => {
      isAnimating.value = false

      if (currentIndex.value < 0) {
        currentIndex.value = slides.total.value
      } else if (currentIndex.value > slides.total.value) {
        currentIndex.value = 0
      }
    }, 500)
  }

  const lastSlide = computed(() => slides.total.value - slidesPerPage.value + 2)

  function slideRight(): void {
    if (!canSlideRight.value || isAnimating.value) {
      return
    }

    const nextSlide = infinite
      ? currentIndex.value + slidesPerPage.value
      : Math.min(currentIndex.value + slidesPerPage.value, lastSlide.value - 1)

    slideTo(nextSlide)
  }

  function slideLeft(): void {
    if (!canSlideLeft.value || isAnimating.value) {
      return
    }

    const nextSlide = infinite
      ? currentIndex.value - slidesPerPage.value
      : Math.max(0, currentIndex.value - slidesPerPage.value)

    slideTo(nextSlide)
  }

  function stopAutoplay(): void {
    isPlaying.value = false
  }

  function toggleAutoplay(): void {
    isPlaying.value = !isPlaying.value
  }

  function calculateSlideWidth(): void {
    slideWidth.value = sliderWrapperRef.value?.clientWidth / slidesPerPage.value
  }

  const canSlideLeft = computed(() => infinite || currentIndex.value > 0)
  const canSlideRight = computed(
    () =>
      infinite || currentIndex.value + slidesPerPage.value <= slides.total.value
  )

  const position = computed(() => {
    return (
      -1 *
      Math.floor(
        (currentIndex.value + (infinite ? 1 : 0)) * slideWidth.value +
          offset.value
      )
    )
  })

  watchEffect(() => {
    if (
      !infinite &&
      currentIndex.value > slides.total.value &&
      slides.total.value > 0
    ) {
      slideTo(slides.total.value)
    }

    if (currentIndex.value + slides.preload > lastSlideToPreload.value) {
      lastSlideToPreload.value = currentIndex.value + slides.preload
    }
  })

  watch(
    slidesVisible,
    () => {
      slidesPerPage.value = slidesVisible.value
      calculateSlideWidth()
    },
    {
      immediate: true,
    }
  )

  watch(
    [currentIndex, isPlaying],
    async () => {
      if (!isPlaying.value) {
        return
      }

      await nextTick()
      clearTimeout(autoplayTimeout.value) // Clear any existing timeouts
      autoplayTimeout.value = setTimeout(() => {
        if (isPlaying.value) {
          slideRight()
        }
      }, slideDuration.value)
    },
    { immediate: true }
  )

  useResizeObserver(sliderWrapperRef, () => {
    calculateSlideWidth()
  })

  onMounted(async () => {
    calculateSlideWidth()
    await nextTick()

    if (infinite) {
      prefixSlide.value = slides.total.value
    }
  })

  return {
    currentIndex,
    isPlaying,
    position,
    isDragging,
    isAnimating,
    onTouchStart,
    slideTo,
    slideLeft,
    slideRight,
    onTouchEnd,
    onTouchMove,
    canSlideLeft,
    canSlideRight,
    lastSlideToPreload,
    stopAutoplay,
    toggleAutoplay,
    getWrapperRef,
    prefixSlide,
    suffixSlide,
    sliderWrapperRef,
  }
}
