import type {
  Movie,
  FetchShowGroupForMovieInfosModalQueryVariables,
  FetchShowGroupForMovieInfosPageQueryVariables,
} from '#gql/default'

export default async function useShowGroup({
  operation,
  variables,
}: {
  operation:
    | 'FetchShowGroupForMovieInfosPage'
    | 'FetchShowGroupForMovieInfosModal'
  variables: Ref<
    | FetchShowGroupForMovieInfosModalQueryVariables
    | FetchShowGroupForMovieInfosPageQueryVariables
  >
}) {
  const { data, pending } = await useAsyncGql({ operation, variables })
  const showGroup = computed(() => data.value?.showGroup)
  const movie = computed(() => data.value?.showGroup?.movie as Movie)

  return {
    showGroup,
    movie,
    pending,
  }
}
