import { useEventBus } from '@vueuse/core'

interface Preselected {
  date?: string
  search?: string
  city?: string
  genres?: string[]
  contentRatings?: string[]
  featureFlags?: string[]
  showGroups?: string[]
  periods?: string[]
  types?: string[]
  auditoriums?: string[]
  flags?: string[]
  languageFlags?: string[]
  technologyFlags?: string[]
  miscellaneousFlags?: string[]
  eventFlags?: string[]
  cinemaIds?: string[]
  dates?: string[]
  movieId?: string
}

const { CITY, DATE, SEARCH } = URL_FILTER

export default function useFetchParams({
  preselected,
  filters = [],
  urlPrefix,
}: {
  preselected?: Preselected
  filters?: UrlFilter[]
  urlPrefix?: string
} = {}) {
  const route = useRoute()
  const bus = useEventBus([['filters', urlPrefix]].filter(Boolean).join('_'))
  const urlValues = reactive({})

  function updateFilterValue(newValues: { [key: string]: string }) {
    Object.keys(newValues).forEach((filter) => {
      if (newValues[filter] !== undefined) {
        if (filter === DATE || filter === CITY || filter === SEARCH) {
          urlValues[filter] = newValues[filter]
        } else if (Array.isArray(newValues[filter])) {
          urlValues[filter] = newValues[filter].length
            ? urlValues[filter]
            : undefined
        } else {
          urlValues[filter] = newValues[filter]?.split(',')
        }
      } else {
        urlValues[filter] = undefined
      }
    })
  }

  bus.on((event) => {
    updateFilterValue(event)
  })

  const query = route.query
  filters.forEach((filter) => {
    const key = getFilterUrlKey(filter, urlPrefix)
    const value = query[key]

    if (!value) {
      delete urlValues[filter]
    } else if (filter === DATE || filter === CITY || filter === SEARCH) {
      urlValues[filter] = value
    } else {
      urlValues[filter] = value.split(',')
    }
  })

  return {
    hasFiltersApplied: computed(() => !!Object.values(urlValues).length),
    fetchParams: computed(() => {
      const values = { ...preselected, ...urlValues }

      Object.entries(values).forEach(([key, value]) => {
        if (!value || value.length === 0) {
          delete values[key]
        }
      })

      return values
    }),
  }
}
